import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper } from "@material-ui/core";

import Loading from "../ui/Loading";
import QuestionBalloon from "../ui/QuestionBalloon";
import QuestionChoiceVertical from "../ui/QuestionChoiceVertical";
import QuestionChoiceHorizontal from "../ui/QuestionChoiceHorizontal";
import QuestionChoiceMultiple from "../ui/QuestionChoiceMultiple";

import { IQuestionDetail, IAnswerClick } from "../../models/model";

import { createSimpleTracking } from "../../useTracking";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
        display: "block",
        width: "290px",
        "@media screen and (max-width: 450px)": {
            width: "80vw",
        },
    },
    question: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    grid: {
        margin: "auto",
    },
    returnIcon: {
        padding: "2px",
        width: "10px",
        height: "10px",
        "@media screen and (max-width: 450px)": {
            width: "2vw",
            height: "2vh",
        },
    },
    returnText: {
        padding: "2px",
        fontSize: "10px",
    },
    moveButton: {
        marginTop: theme.spacing(2),
        width: "120px",
        height: "20px",
        "@media screen and (max-width: 450px)": {
            width: "35vw",
            height: "5vh",
        },
    },
    loading: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default function Question() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    // スクロールトップに表示
    window.scrollTo(0, 0);

    // URLパラメータ
    const urlParam = useParams();
    // ロードを表示するか？
    const [isLoading, setLoading] = useState(true);
    // ユーザーID
    const [userId, setUserId] = useState("");
    // 質問番号
    const [qId, setQId] = useState(urlParam.q_id);
    // 質問と回答
    const [questionDetail, setQuestionDetail] = useState<IQuestionDetail>({} as IQuestionDetail);

    // ユーザーID取得API
    const getUser = () => {
        axios.get(`/api/v3/user_id`).then(res => {
            setUserId(res.data);
        });

        // 最初にランキングAPIへダミーの値をPOSTする
        const headers = {
            "Content-Type": "application/json",
            accept: "application/json",
        };
        axios.post(`/api/v3/ranking?user_id=dummy`, { headers: headers });
    };

    // 質問取得API
    const getQuestion = () => {
        axios.get(`/api/v3/question/${qId}?user_id=${userId}`).then(res => {
            if (res.data.method === "end_of_question") {
                createSimpleTracking("goRanking");
                createRanking();
            } else {
                setQuestionDetail(res.data);
                setLoading(false);
            }
        });
    };

    const createRanking = () => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            accept: "application/json",
        };

        axios.post(`/api/v3/ranking?user_id=${userId}`, { headers: headers }).then(() => {
            // 次の質問へ
            navigate(`/ranking?user_id=${userId}`, { state: { type: "success" } });
        });
    };

    // 回答登録API
    const answerClick: IAnswerClick = (answer: string[]) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            accept: "application/json",
        };
        const postData = {
            q_id: questionDetail.q_id,
            answer: answer,
        };
        axios.post(`/api/v3/answer?user_id=${userId}`, postData, { headers: headers }).then(res => {
            // 次の質問へ
            navigate(`/question/${res.data}`, { state: { type: "success" } });
        });
    };

    // 質問取得
    useEffect(() => {
        if (!userId) {
            // 初回はuser_id取得
            getUser();
        } else {
            getQuestion();
        }
    }, [qId]);

    // 初回でuser_idが取得された時のみ質問を取得する
    useEffect(() => {
        if (userId) {
            // user_idがあれば質問取得
            getQuestion();
        }
    }, [userId]);

    // URLが変更されたら画面を再描画
    useEffect(() => {
        setQId(urlParam.q_id);
    }, [location]);

    const returnClick = () => {
        // ひとつ前へ戻る
        navigate(-1);
    };

    // 質問画面
    const questionView = (
        <Paper className={classes.paper} elevation={0}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    {((): JSX.Element => {
                        if (questionDetail.message) return <QuestionBalloon message={questionDetail.message} />;
                        else return <div></div>;
                    })()}
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.question}>{questionDetail.question}</span>
                </Grid>

                {((): JSX.Element => {
                    // 質問を描画
                    if (questionDetail.method === "choices")
                        // 縦並びの文章
                        return <QuestionChoiceVertical answers={questionDetail.options} answerClick={answerClick} />;
                    else if (questionDetail.method === "image_choices" || questionDetail.method === "icon_choices")
                        // 横並びの画像、アイコン
                        return (
                            <QuestionChoiceHorizontal
                                answers={questionDetail.options}
                                answerClick={answerClick}
                                method={questionDetail.method}
                            />
                        );
                    else if (
                        questionDetail.method === "choices_multi" ||
                        questionDetail.method === "icon_choices_multi"
                    )
                        // 複数選択
                        return (
                            <QuestionChoiceMultiple
                                answers={questionDetail.options}
                                method={questionDetail.method}
                                max_answer_count={questionDetail.max_answer_count}
                                min_answer_count={questionDetail.min_answer_count}
                                answerClick={answerClick}
                            />
                        );
                    else return <div></div>;
                })()}
            </Grid>
            <Button
                variant="text"
                className={classes.moveButton}
                color="inherit"
                onClick={() => {
                    returnClick();
                    createSimpleTracking("pushedReturnButton");
                }}
            >
                <img
                    className={classes.returnIcon}
                    alt="complex"
                    src={`${process.env.PUBLIC_URL}/icons/arrow_left.svg`}
                />
                <span className={classes.returnText}>一つ戻る</span>
            </Button>
        </Paper>
    );

    return (
        <div>
            {(() => {
                if (isLoading) return <Loading />;
                else return questionView;
            })()}
        </div>
    );
}
