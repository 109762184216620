import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Loading from "../ui/Loading";
import TownCardBig from "../ui/TownCardBig";
import TownCardSmall from "../ui/TownCardSmall";
import ShareSns from "components/ui/ShareSns";

import { ITown, ITownClick } from "../../models/model";

const useStyles = makeStyles(theme => ({
    rankingList: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
}));

export default function Ranking() {
    const classes = useStyles();
    const navigate = useNavigate();

    // スクロールトップに表示
    window.scrollTo(0, 0);

    // URLパラメータ
    const [searchParams] = useSearchParams();

    // ロードを表示するか？
    const [isLoading, setLoading] = useState<boolean>(true);
    // ユーザーID
    const [userId] = useState<string>(searchParams.get("user_id") as string);
    // 街
    const [townList, setTownList] = useState<ITown[]>([]);

    // ランキング取得API
    const getRanking = () => {
        axios.get(`/api/v3/ranking?user_id=${userId}`).then(res => {
            setTownList(res.data.items);
            setLoading(false);
        });
    };

    // ランキング取得
    useEffect(() => {
        getRanking();
    }, [userId]);

    // 街ページへ
    const townClick: ITownClick = (townId: number) => {
        navigate(`/town_detail/${townId}?user_id=${userId}`, { state: { type: "success" } });
    };

    // ランキング画面
    const rankingView = (
        <div className={classes.rankingList}>
            {/* 結果一覧 */}
            <Grid container direction="column" alignItems="center" spacing={0}>
                {townList.map((town, index) => (
                    <Grid className={classes.root} key={index}>
                        {(() => {
                            if (town.rank && town.rank <= 3)
                                // 1位から3位
                                return <TownCardBig town={town} townClick={townClick} />;
                            // 4位以降
                            else return <TownCardSmall town={town} townClick={townClick} />;
                        })()}
                    </Grid>
                ))}
            </Grid>
            <ShareSns></ShareSns>
        </div>
    );

    return (
        <div>
            {(() => {
                if (isLoading) return <Loading />;
                else return rankingView;
            })()}
        </div>
    );
}
