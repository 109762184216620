import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from "recharts";

export default function Rankcharts(props: {
    childcare: number;
    education: number;
    convenience: number;
    environment: number;
    medical: number;
    security: number;
}) {
    const data = [
        {
            subject: "子育て",
            score: props.childcare,
            fullMark: 5,
        },
        {
            subject: "教育",
            score: props.education,
            fullMark: 5,
        },
        {
            subject: "利便性",
            score: props.convenience,
            fullMark: 5,
        },
        {
            subject: "周辺環境",
            score: props.environment,
            fullMark: 5,
        },
        {
            subject: "医療",
            score: props.medical,
            fullMark: 5,
        },
        {
            subject: "治安防災",
            score: props.security,
            fullMark: 5,
        },
    ];

    return (
        <RadarChart cx={"50%"} cy={"50%"} outerRadius={80} width={300} height={220} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis angle={30} domain={[0, 5]} />
            <Radar dataKey="score" stroke="#FFA080" fill="#FFA080" fillOpacity={0.6} />
        </RadarChart>
    );
}
