import { Modal, Backdrop, Fade, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createPortal } from "react-dom";

const useStyles = makeStyles(() => ({
    modal: {
        width: "375px",
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        outline: "none",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    modalPaper: {
        position: "relative",
        display: "inline-block",
    },
    bannerImageArea: {
        display: "block",
        maxWidth: "100%",
        height: "auto",
    },
    bannerImage: {
        width: "375px",
        height: "auto",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    buttonArea: {
        width: "375px",
        textAlign: "center",
        transform: "translate(0%, -110%)",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    showButton: {
        width: "260px",
        height: "40px",
        border: "2px solid #6DD1F2",
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: "1",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "bold",
    },
    returnButton: {
        width: "260px",
        height: "40px",
        border: "2px solid #6DD1F2",
        borderImage: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: "1",
        background: "#000000",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "bold",
    },
}));

export default function PopUpRich(props: {
    id: string;
    isModalOpen: boolean;
    setIsModalOpen: (param: boolean) => void;
    popUpImage: string;
    buttonUrl: string;
    buttonWord: string;
    hideBackdrop: boolean;
}) {
    const classes = useStyles();

    const isModalOpen = props.isModalOpen;
    const setIsModalOpen = props.setIsModalOpen;

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return createPortal(
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            hideBackdrop={props.hideBackdrop}
            disableAutoFocus={true}
            id="floating_modal"
        >
            <Fade in={isModalOpen}>
                <div className={classes.modal}>
                    <div className={classes.modalPaper}>
                        <div className={classes.bannerImage}>
                            <img
                                alt="complex"
                                className={classes.bannerImage}
                                src={`${process.env.PUBLIC_URL}/images/${props.popUpImage}`}
                            />
                        </div>
                        <div className={classes.buttonArea}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Button
                                        variant="outlined"
                                        className={classes.showButton}
                                        onClick={() => {
                                            window.open(props.buttonUrl);
                                        }}
                                    >
                                        {props.buttonWord}
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        id={`close_button_${props.id}`}
                                        variant="outlined"
                                        className={classes.returnButton}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        閉じる
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>,
        document.body,
    );
}
