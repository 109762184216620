import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./components/page/Header";
import Footer from "./components/page/Footer";
import Top from "./components/page/Top";
import Question from "./components/page/Question";
import Ranking from "./components/page/Ranking";
import TownDetail from "./components/page/TownDetail";

import { usePageTracking } from "./useTracking";

function App() {
    const useStyles = makeStyles(() => ({
        back: {
            margin: "auto",
            display: "block",
            background: "#FFFFFF",
            width: "375px",
            minHeight: "835px",
            "@media screen and (max-width: 450px)": {
                width: "100vw",
            },
        },
    }));

    const classes = useStyles();

    // GA
    usePageTracking();

    return (
        <div>
            <Paper className={classes.back} elevation={0}>
                <Header />
                <Routes>
                    <Route path="/" element={<Top />} />
                    <Route path="/question/:q_id" element={<Question />} />
                    <Route path="/ranking" element={<Ranking />} />
                    <Route path="/town_detail/:town_id" element={<TownDetail />} />
                </Routes>
                <Footer />
            </Paper>
        </div>
    );
}

export default App;
