import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Rankcharts from "./Rankcharts";
import Category from "./Category";

import { ITownDetail, ICategory } from "../../models/model";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(-1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "block",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    contentCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    matchingScoreTitleArea: {
        paddingLeft: theme.spacing(2),
        display: "flex",
        alignItems: "center",
    },
    matchingScoreTitle: {
        fontWeight: "bolder",
        fontSize: "16px",
        paddingRight: "5px",
    },
    matchingScore: {
        fontSize: "24px",
        color: "#FF8459",
        fontWeight: "bolder",
    },
}));

export default function TownDetailMatchingScore(props: { townDetail: ITownDetail; categories: ICategory[] }) {
    const classes = useStyles();

    const townDetail = props.townDetail;
    const categories = props.categories;

    return (
        <Paper className={classes.paper} square elevation={0}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs>
                    <div className={classes.matchingScoreTitleArea}>
                        <span className={classes.matchingScoreTitle}>あなたとのマッチ度</span>
                        <span className={classes.matchingScore}>{`${townDetail.matching_score}%`}</span>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.contentCenter}>
                        <Rankcharts
                            childcare={categories[0].score}
                            education={categories[1].score}
                            convenience={categories[2].score}
                            environment={categories[3].score}
                            medical={categories[4].score}
                            security={categories[5].score}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container item direction="column" alignItems="center" spacing={2}>
                {categories.map((category, index) => (
                    <Category category={category} key={index} />
                ))}
            </Grid>
        </Paper>
    );
}
