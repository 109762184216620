import { Grid, Card, CardActionArea } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ITown, ITownClick } from "../../models/model";

import { createTrackingWithUserIdTownId } from "../../useTracking";

const useStyles = makeStyles(() => ({
    card: {
        padding: "15px",
        margin: "4px",
        border: "2px solid #6DD1F2",
        borderImage: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: "1",
        width: "290px",
    },
    rankIcon: {
        width: "36px",
        height: "36px",
        "@media screen and (max-width: 450px)": {
            width: "10vw",
            height: "4vh",
        },
    },
    cityName: {
        fontSize: "14px",
        textAlign: "left",
        fontWeight: "bolder",
    },
    moveTownIcon: {
        color: "#67B4B6",
        width: "10px",
        height: "10px",
        "@media screen and (max-width: 450px)": {
            width: "10vw",
            height: "1vh",
        },
    },
}));

export default function TownCardSmall(props: { town: ITown; townClick: ITownClick }) {
    const classes = useStyles();

    const town = props.town;

    const townClick = (townId: number) => {
        props.townClick(townId);
    };

    return (
        <div>
            <CardActionArea
                onClick={() => {
                    townClick(town.town_id);
                    createTrackingWithUserIdTownId("pushedViewDetail");
                }}
            >
                <Card className={classes.card} elevation={0}>
                    <Grid container item direction="row" alignItems="center" xs={12}>
                        <Grid item xs={2}>
                            {((): JSX.Element => {
                                if (town.rank)
                                    return (
                                        <img
                                            className={classes.rankIcon}
                                            alt="complex"
                                            src={`${process.env.PUBLIC_URL}/icons/no${town.rank}.svg`}
                                        />
                                    );
                                else return <div></div>;
                            })()}
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.cityName}>{`${town.ward_name} ${town.town_name}`}</div>
                        </Grid>
                        <Grid item xs={1}>
                            <span className={classes.moveTownIcon}>＞</span>
                        </Grid>
                    </Grid>
                </Card>
            </CardActionArea>
        </div>
    );
}
