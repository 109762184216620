import { Grid, ButtonBase, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Call from "@material-ui/icons/Call";
import Mail from "@material-ui/icons/Mail";
import { LineIcon } from "react-share";

import { ITownDetail } from "../../models/model";
import { createTrackingWithUserIdTownId } from "../../useTracking";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    miniPaper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "320px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    explanationBig: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    explanatoryText: {
        fontSize: "14px",
        textAlign: "center",
        fontWeight: "bolder",
        color: "#1e90ff",
    },
    title: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    address: {
        fontSize: "2px",
        textAlign: "center",
    },
    contactIconArea: {
        display: "flex",
        justifyContent: "center",
    },
    contactIcon: {
        width: "20px",
        height: "20px",
        "@media screen and (max-width: 450px)": {
            width: "8vw",
            height: "4vh",
        },
    },
    contactIconText: {
        fontSize: "10px",
        textAlign: "center",
        fontWeight: "bolder",
    },
}));

export default function TownDetailRealEstateAgencySelection(props: { townDetail: ITownDetail }) {
    const classes = useStyles();

    const townDetail = props.townDetail;

    console.log("いいいいい");
    console.log(townDetail);

    return (
        <div>
            <Paper className={classes.paper} square elevation={0}>
                <Paper className={classes.miniPaper} square elevation={0}>
                    <Grid container direction="column" alignItems="flex-start" spacing={2}>
                        <Grid item xs>
                            <span className={classes.explanationBig}>お急ぎの方はこちら（不動産屋３選）</span>
                        </Grid>
                        <Grid container item direction="column" alignItems="flex-start" spacing={1}>
                            <Grid item xs>
                                <span className={classes.explanatoryText}>
                                    働くビジネスパーソン、ファミリーにお勧め
                                </span>
                            </Grid>
                            <Grid container item xs direction="row" justifyContent="flex-start" spacing={0}>
                                <Grid item xs={6}>
                                    <span className={classes.title}>(株)TAG Innovations</span>
                                </Grid>
                                <Grid item xs={2} className={classes.contactIconArea}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedCallButton");
                                            window.location.href = "tel:03-6822-5717";
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs>
                                                <Call htmlColor="#1d83bd" />
                                            </Grid>
                                            <Grid item xs>
                                                <span className={classes.contactIconText}>電話</span>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={2} className={classes.contactIconArea}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedContactButton");
                                            window.open("https://tag-is.com/contact/");
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs>
                                                <Mail htmlColor="#1d83bd" />
                                            </Grid>
                                            <Grid item xs>
                                                <span className={classes.contactIconText}>お問合せ</span>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={2}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedLineButton");
                                            window.open("https://user.tokyo-story.net/Partner_TAG_Innovations");
                                        }}
                                    >
                                        <LineIcon size={32} round />
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" alignItems="flex-start" spacing={1}>
                            <Grid item xs>
                                <span className={classes.explanatoryText}>女性はこちら一択！（上京女性社長）</span>
                            </Grid>
                            <Grid container item xs direction="row" justifyContent="flex-start" spacing={0}>
                                <Grid item xs={6}>
                                    <span className={classes.title}>株式会社MIKIA</span>
                                </Grid>
                                <Grid item xs={2} className={classes.contactIconArea}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedCallButton");
                                            window.location.href = "tel:03-6912-0461";
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs>
                                                <Call htmlColor="#1d83bd" />
                                            </Grid>
                                            <Grid item xs>
                                                <span className={classes.contactIconText}>電話</span>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={2} className={classes.contactIconArea}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedContactButton");
                                            window.open("https://mikia.jp/contactus.html#contents");
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs>
                                                <Mail htmlColor="#1d83bd" />
                                            </Grid>
                                            <Grid item xs>
                                                <span className={classes.contactIconText}>お問合せ</span>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={2}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedLineButton");
                                            window.open("https://user.tokyo-story.net/Partner_MIKIA");
                                        }}
                                    >
                                        <LineIcon size={32} round />
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" alignItems="flex-start" spacing={1}>
                            <Grid item xs>
                                <span className={classes.explanatoryText}>単身の方、家賃は抑えめの下町好きの方は</span>
                            </Grid>
                            <Grid container item xs direction="row" justifyContent="flex-start" spacing={0}>
                                <Grid item xs={6}>
                                    <span className={classes.title}>Maison Labo</span>
                                </Grid>
                                <Grid item xs={2} className={classes.contactIconArea}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedCallButton");
                                            window.location.href = "tel:03-5249-3121";
                                        }}
                                    >
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item xs>
                                                <Call htmlColor="#1d83bd" />
                                            </Grid>
                                            <Grid item xs>
                                                <span className={classes.contactIconText}>電話</span>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}>
                                    <ButtonBase
                                        focusRipple
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            createTrackingWithUserIdTownId("pushedLineButton");
                                            window.open("https://user.tokyo-story.net/Partner_MaisonLab");
                                        }}
                                    >
                                        <LineIcon size={32} round />
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </div>
    );
}
