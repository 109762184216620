import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "block",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    contentCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    mapTitleArea: {
        paddingLeft: theme.spacing(2),
    },
    mapTitle: {
        fontWeight: "bolder",
        fontSize: "16px",
    },
}));

export default function TownDetailThird(props: { iframeSource: string }) {
    const classes = useStyles();

    const iframeSource = props.iframeSource;

    return (
        <Paper className={classes.paper} square elevation={0}>
            <Grid container direction="column" spacing={1}>
                <Grid item xs>
                    <div className={classes.mapTitleArea}>
                        <span className={classes.mapTitle}>周辺地図</span>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.contentCenter} dangerouslySetInnerHTML={{ __html: iframeSource }}></div>
                </Grid>
            </Grid>
        </Paper>
    );
}
