import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";

import { IAnswerClickChild, IAnswer, IQuestionIconState } from "../../models/model";

const useStyles = makeStyles(theme => ({
    image: {
        position: "relative",
        width: "100%",
        height: "110px",
        border: (props: IQuestionIconState) => (props.isImageBackdrop === "show" ? "" : "2px solid #6DD1F2"),
        borderImage: (props: IQuestionIconState) =>
            props.isImageBackdrop === "show" ? "" : "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: (props: IQuestionIconState) => (props.isImageBackdrop === "show" ? "" : "1"),
        "@media screen and (max-height: 700px)": {
            height: "15vh",
        },
        "@media screen and (min-height:700px) and  (max-height: 820px)": {
            height: "13vh",
        },
        "&:hover": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.15,
            },
        },
    },
    imageSrc: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundPosition: "center 40%",
    },
    imageBackdrop: {
        borderRadius: "10px",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create("opacity"),
    },
    titleArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: "11px",
        margin: "4px",
    },
}));

export default function QuestionIcon(props: {
    answer: IAnswer;
    answerClick: IAnswerClickChild;
    isImageBackdrop: string;
    isDisabled: boolean;
}) {
    const classes = useStyles({
        isImageBackdrop: props.isImageBackdrop,
    });

    const answer = props.answer;
    const isImageBackdrop = props.isImageBackdrop;
    const isDisabled = props.isDisabled;

    return (
        <div>
            <div>
                <ButtonBase
                    focusRipple
                    className={classes.image}
                    style={{
                        width: "100%",
                    }}
                    onClick={() => {
                        props.answerClick(answer.value);
                    }}
                    disabled={isDisabled}
                    data-test={answer.name}
                >
                    <span
                        className={classes.imageSrc}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/icons/${answer.image})`,
                        }}
                    />
                    <span className={isImageBackdrop === "show" ? classes.imageBackdrop : ""}></span>
                </ButtonBase>
            </div>
            <div className={classes.titleArea}>
                <span className={classes.title}>{answer.name}</span>
            </div>
        </div>
    );
}
