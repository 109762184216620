import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import QuestionSentence from "./QuestionSentence";
import QuestionIcon from "./QuestionIcon";

import { IAnswerClick, IAnswer } from "../../models/model";

const useStyles = makeStyles(theme => ({
    grid: {
        margin: "auto",
    },
    returnIcon: {
        width: "20px",
        height: "20px",
        padding: "2px",
    },
    returnText: {
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "bolder",
        padding: "2px",
    },
    moveButton: {
        justifyContent: "center",
        marginTop: theme.spacing(1),
        width: "200px",
        height: "70px",
    },
}));

export default function QuestionChoiceMultiple(props: {
    answers: IAnswer[];
    method: string;
    min_answer_count: number;
    max_answer_count: number;
    answerClick: IAnswerClick;
}) {
    const classes = useStyles();

    // 選択した回答を保持
    const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

    const answerClick = (select_answer: string) => {
        if (selectedAnswers.includes(select_answer)) {
            // すでに選択されている回答だったら削除
            setSelectedAnswers(selectedAnswers.filter(answer => answer !== select_answer));
        } else {
            // まだ選択されていなければ選択した回答をセット
            setSelectedAnswers([...selectedAnswers, select_answer]);
        }
    };

    const isDisabledCheck = (answer: IAnswer) =>
        selectedAnswers.length === props.max_answer_count && !selectedAnswers.includes(answer.value);

    return (
        <Grid container item xs={12} direction="column" alignItems="center" spacing={2}>
            <Grid container item xs={12} direction="row" alignItems="center" spacing={2}>
                {props.answers.map((answer, index) => (
                    <Grid item xs={6} className={classes.grid} key={index}>
                        <>
                            {props.method === "choices_multi" ? (
                                // 文字
                                <QuestionSentence
                                    answer={answer}
                                    answerClick={answerClick}
                                    buttonColor={selectedAnswers.includes(answer.value) ? "primary" : "default"}
                                    buttonStyle={"horizontal"}
                                    isDisabled={isDisabledCheck(answer)}
                                />
                            ) : (
                                // アイコン
                                <QuestionIcon
                                    answer={answer}
                                    answerClick={answerClick}
                                    isImageBackdrop={selectedAnswers.includes(answer.value) ? "hidden" : "show"}
                                    isDisabled={isDisabledCheck(answer)}
                                />
                            )}
                        </>
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <Button
                    variant="text"
                    color="inherit"
                    onClick={() => {
                        props.answerClick(selectedAnswers);
                    }}
                    disabled={selectedAnswers.length >= props.min_answer_count ? false : true}
                >
                    <span className={classes.returnText}>次へ</span>
                    <img
                        className={classes.returnIcon}
                        alt="complex"
                        src={`${process.env.PUBLIC_URL}/icons/arrow_right.svg`}
                    />
                </Button>
            </Grid>
        </Grid>
    );
}
