import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ITownDetail } from "../../models/model";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
        margin: "auto",
        display: "block",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    townNameArea: {
        paddingLeft: theme.spacing(5),
        display: "flex",
        alignItems: "center",
    },
    rankIcon: {
        padding: "5px",
        objectFit: "cover",
        width: "50px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            width: "15vw",
            height: "15vw",
        },
    },
    townName: {
        fontWeight: "bolder",
        fontSize: "20px",
    },
    tagNameArea: {
        paddingLeft: theme.spacing(5),
    },
    tagName: {
        color: "#FFFFFF",
        backgroundColor: "#BAA488",
        padding: "4px",
        margin: "5px",
        fontSize: "14px",
        fontWeight: "bolder",
        width: "35px",
        height: "15px",
        "@media screen and (max-width: 450px)": {
            width: "10vw",
            height: "2vh",
        },
    },
    name: {
        fontSize: "14px",
    },
    cityImage: {
        marginTop: theme.spacing(1),
        objectFit: "cover",
        width: "375px",
        height: "200px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
            height: "25vh",
        },
    },
}));

export default function TownDetailHead(props: { townDetail: ITownDetail }) {
    const classes = useStyles();

    const townDetail = props.townDetail;

    return (
        <Paper className={classes.paper} square elevation={0}>
            <Grid container item direction="column" spacing={1}>
                <Grid item xs>
                    <div className={classes.townNameArea}>
                        {((): JSX.Element => {
                            if (townDetail.rank)
                                return (
                                    <img
                                        className={classes.rankIcon}
                                        alt="complex"
                                        src={`${process.env.PUBLIC_URL}/icons/no${townDetail.rank}.svg`}
                                    />
                                );
                            else return <div></div>;
                        })()}
                        <span className={classes.townName}>{`${townDetail.ward_name} ${townDetail.town_name}`}</span>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.tagNameArea}>
                        <span className={classes.tagName}>最寄駅</span>
                        <span className={classes.name}>{townDetail.nearest_station}</span>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.tagNameArea}>
                        <span className={classes.tagName}>通勤時間</span>
                        <span className={classes.name}>{`${townDetail.time_to_workplace}分`}</span>
                    </div>
                </Grid>
                <Grid item xs>
                    <img className={classes.cityImage} alt="complex" src={`/api/v3/town_image/` + townDetail.town_id} />
                </Grid>
            </Grid>
        </Paper>
    );
}
