import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import QuestionSentence from "./QuestionSentence";

import { IAnswerClick, IAnswer } from "../../models/model";

const useStyles = makeStyles(() => ({
    grid: {
        margin: "auto",
    },
}));

export default function QuestionChoiceVertical(props: { answers: IAnswer[]; answerClick: IAnswerClick }) {
    const classes = useStyles();

    const answerClick = (answer: string) => {
        props.answerClick([answer]);
    };

    return (
        <Grid container item xs={12} direction="column" alignItems="center" spacing={2}>
            {props.answers.map((answer, index) => (
                <Grid item xs={12} className={classes.grid} key={index}>
                    <QuestionSentence
                        answer={answer}
                        answerClick={answerClick}
                        buttonColor={"primary"}
                        buttonStyle={"vertical"}
                        isDisabled={false}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
