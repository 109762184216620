import { Link, useLocation } from "react-router-dom";

import { Toolbar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createSimpleTracking } from "../../useTracking";

const useStyles = makeStyles(theme => ({
    head: {
        margin: theme.spacing(1),
        height: "50px",
    },
    logo: {
        width: "100px",
        height: "50px",
    },
}));

export default function Header() {
    const classes = useStyles();
    const location = useLocation();

    const header = (
        <Toolbar component={Link} to="/" variant="dense" className={classes.head}>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={4}>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/title.svg`}
                        className={classes.logo}
                        alt="上京物語"
                        onClick={() => {
                            createSimpleTracking("pushedHeaderReturn");
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <img
                        src={`${process.env.PUBLIC_URL}/icons/headerillust.svg`}
                        alt="上京物語"
                        onClick={() => {
                            createSimpleTracking("pushedHeaderReturn");
                        }}
                    />
                </Grid>
            </Grid>
        </Toolbar>
    );

    return (
        <div>
            {((): JSX.Element => {
                if (location.pathname !== "/") return header;
                else return <div></div>;
            })()}
        </div>
    );
}
