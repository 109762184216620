import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase, Typography } from "@material-ui/core";

import { IAnswerClickChild, IAnswer } from "../../models/model";

const useStyles = makeStyles(theme => ({
    image: {
        position: "relative",
        width: "100%",
        height: "110px",
        "@media screen and (max-height: 700px)": {
            height: "15vh",
        },
        "@media screen and (min-height:700px) and  (max-height: 820px)": {
            height: "13vh",
        },
        "&:hover": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.15,
            },
        },
    },
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white,
    },
    imageButtonHidden: {
        display: "none",
    },
    imageSrc: {
        borderRadius: "10px",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundPosition: "center 40%",
    },
    imageBackdrop: {
        borderRadius: "10px",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create("opacity"),
    },
    imageTitle: {
        fontSize: "14px",
        fontWeight: "bolder",
        position: "relative",
    },
}));

export default function QuestionImage(props: {
    answer: IAnswer;
    answerClick: IAnswerClickChild;
    isImageBackdrop: string;
    isImageName: string;
    isDisabled: boolean;
}) {
    const classes = useStyles();

    const answer = props.answer;
    const isImageBackdrop = props.isImageBackdrop;
    const isImageName = props.isImageName;
    const isDisabled = props.isDisabled;

    return (
        <ButtonBase
            focusRipple
            className={classes.image}
            style={{
                width: "100%",
            }}
            onClick={() => {
                props.answerClick(answer.value);
            }}
            disabled={isDisabled}
        >
            <span
                className={classes.imageSrc}
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${answer.image})`,
                }}
            />
            <span className={isImageBackdrop === "show" ? classes.imageBackdrop : ""}></span>
            <span className={isImageName === "show" ? classes.imageButton : classes.imageButtonHidden}>
                <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                    {answer.name}
                </Typography>
            </span>
        </ButtonBase>
    );
}
