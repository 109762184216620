import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ShareLine from "./ShareLine";
import ShareTwitter from "./ShareTwitter";
import ShareMail from "./ShareMail";
import ShareURL from "./ShareURL";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "12px",
        margin: "auto",
        display: "block",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    grid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    shareText: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    friendText: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    button: {
        color: "#FFFFFF",
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderRadius: "30px",
        marginTop: theme.spacing(1),
        width: "200px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            width: "48vw",
        },
    },
}));

export default function ShareSns() {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paper} square elevation={0}>
                <Grid container className={classes.grid} item direction="column" alignItems="center" spacing={1} xs>
                    <Grid item xs>
                        <span className={classes.shareText}>診断結果をシェアする</span>
                    </Grid>
                    <Grid item xs>
                        <ShareLine />
                    </Grid>
                    <Grid item xs>
                        <ShareTwitter />
                    </Grid>
                    <Grid item xs>
                        <ShareMail />
                    </Grid>
                    <Grid item xs>
                        <ShareURL />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
