import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { IAnswerClickChild, IAnswer, IQuestionButtonState } from "../../models/model";

const useStyles = makeStyles(() => ({
    answer: {
        textAlign: "center",
        fontSize: (props: IQuestionButtonState) => (props.buttonStyle === "vertical" ? "16px" : "16px"),
        color: "#000000",
    },
    choiseButton: {
        border: (props: IQuestionButtonState) =>
            props.buttonColor === "primary" ? "2px solid #6DD1F2" : "1px solid rgba(0, 0, 0, 0.12)",
        borderImage: (props: IQuestionButtonState) =>
            props.buttonColor === "primary" ? "linear-gradient(to right, #6DD1F2, #7AEBB7)" : "",
        borderImageSlice: (props: IQuestionButtonState) => (props.buttonColor === "primary" ? "1" : ""),
        width: (props: IQuestionButtonState) => (props.buttonStyle === "vertical" ? "300px" : "150px"),
        height: "60px",
        "@media screen and (max-width: 450px)": {
            width: (props: IQuestionButtonState) => (props.buttonStyle === "vertical" ? "80vw" : "40vw"),
        },
        "@media screen and (max-height: 700px)": {
            height: "10vh",
        },
        "@media screen and (min-height:700px) and  (max-height: 820px)": {
            height: "8vh",
        },
    },
}));

export default function QuestionSentence(props: {
    answer: IAnswer;
    answerClick: IAnswerClickChild;
    buttonColor: string;
    buttonStyle: string;
    isDisabled: boolean;
}) {
    const classes = useStyles({
        buttonStyle: props.buttonStyle,
        buttonColor: props.buttonColor,
    });

    const answer = props.answer;
    const isDisabled = props.isDisabled;

    return (
        <Button
            variant="outlined"
            className={classes.choiseButton}
            onClick={() => {
                props.answerClick(answer.value);
            }}
            disabled={isDisabled}
        >
            <span className={classes.answer}>{answer.name}</span>
        </Button>
    );
}
