import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Paper, TextField, ListItem, ListItemText } from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";

import { ITown } from "../../models/model";

const useStyles = makeStyles(theme => ({
    miniPaper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "block",
        backgroundColor: "#FFFFF",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    townSearchTitleArea: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    townSearchTitle: {
        fontWeight: "bolder",
        fontSize: "16px",
    },
    searchBoxArea: {
        paddingBottom: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    searchBox: {
        width: "320px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    searching: {
        fontSize: "18px",
        color: "#7F7F7F",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    searchListArea: {
        padding: "0",
    },
    searchList: {
        fontSize: "14px",
        color: "#1D83BD",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function TownDetailTownSearch(props: { userId: string }) {
    const classes = useStyles();

    const userId = props.userId;

    // 検索ワード
    const [keyword, setKeyword] = useState("");
    // 表示する町一覧
    const [townList, setTownList] = useState<ITown[]>([]);
    // 町一覧を表示するか
    const [showTownList, setShowTownList] = useState(false);
    // 検索中ならtrue
    const [searching, setSearching] = useState(false);
    // 検索結果がなければtrue
    const [nonResult, setNonResult] = useState(false);

    // 検索ボックスに文字が入力されたら検索
    useEffect(() => {
        if (keyword) {
            getTownId();
        }
    }, [keyword]);

    // 検索ボックスが空になったら街を非表示にする
    useEffect(() => {
        if (!keyword && showTownList) {
            setTownList([]);
            setShowTownList(false);
        }
    }, [showTownList]);

    // 街取得API
    const getTownId = () => {
        setShowTownList(false);
        setSearching(true);
        setNonResult(false);
        axios.get(`/api/v3/town_id?town_name=${keyword}`).then(res => {
            if (res.data.items.length === 0) {
                setSearching(false);
                setNonResult(true);
            } else {
                // 検索結果があれば表示
                setTownList(res.data.items);
                setShowTownList(true);
                setSearching(false);
            }
        });
    };

    return (
        <Paper className={classes.miniPaper} square elevation={0}>
            <div className={classes.townSearchTitleArea}>
                <span className={classes.townSearchTitle}>他の街は町名入力で診断</span>
            </div>
            <div className={classes.searchBoxArea}>
                <TextField
                    id="outlined-basic"
                    color="primary"
                    variant="outlined"
                    label="例：豊洲"
                    className={classes.searchBox}
                    onChange={e => setKeyword(e.target.value)}
                    onClick={() => setShowTownList(true)}
                />
            </div>
            {searching && (
                <ListItem alignItems="center">
                    <ListItemText primary="検索中..." className={classes.searching} />
                </ListItem>
            )}
            {!showTownList && nonResult && (
                <ListItem alignItems="center">
                    <ListItemText primary="検索結果なし" className={classes.searching} />
                </ListItem>
            )}
            {showTownList &&
                townList.map((v, i) => (
                    <ListItem alignItems="center" key={i} className={classes.searchListArea}>
                        <ListItemText>
                            <Link to={`/town_detail/${v.town_id}?user_id=${userId}`} className={classes.searchList}>
                                {`${v.ward_name} ${v.town_name}`}
                            </Link>
                        </ListItemText>
                    </ListItem>
                ))}
        </Paper>
    );
}
