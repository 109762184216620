import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import QuestionImage from "./QuestionImage";
import QuestionIcon from "./QuestionIcon";

import { IAnswerClick, IAnswer } from "../../models/model";

const useStyles = makeStyles(() => ({
    grid: {
        margin: "auto",
    },
}));

export default function QuestionChoiceHorizontal(props: {
    answers: IAnswer[];
    answerClick: IAnswerClick;
    method: string;
}) {
    const classes = useStyles();

    const answerClick = (answer: string) => {
        props.answerClick([answer]);
    };

    return (
        <Grid container item xs={12} direction="row" alignItems="center" spacing={2}>
            {props.answers.map((answer, index) => (
                <Grid item xs={6} className={classes.grid} key={index}>
                    <>
                        {props.method === "image_choices" ? (
                            <QuestionImage
                                answer={answer}
                                answerClick={answerClick}
                                isImageBackdrop={"show"}
                                isImageName={"show"}
                                isDisabled={false}
                            />
                        ) : (
                            <QuestionIcon
                                answer={answer}
                                answerClick={answerClick}
                                isImageBackdrop={"hidden"}
                                isDisabled={false}
                            />
                        )}
                    </>
                </Grid>
            ))}
        </Grid>
    );
}
