import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ICategory } from "../../models/model";

const useStyles = makeStyles(() => ({
    categoryIconArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    categoryIcon: {
        width: "60px",
        height: "60px",
        "@media screen and (max-width: 450px)": {
            width: "16vw",
            height: "7vh",
        },
    },
    categoryArea: {
        borderBottom: "1px solid #dcdcdc",
        margin: "2px",
    },
    categoryNameArea: {
        display: "flex",
        alignItems: "center",
    },
    categoryName: {
        fontWeight: "bolder",
        fontSize: "16px",
        paddingRight: "5px",
    },
    starMark: {
        fontWeight: "bolder",
        fontSize: "16px",
        color: "#FF8459",
    },
    categoryDetail: {
        display: "flex",
        padding: "1px",
        justifyContent: "space-between",
    },
    categoryDetailTitle: {
        display: "inline-block",
        fontSize: "14px",
    },
    categoryDetailContent: {
        display: "inline-block",
        fontSize: "14px",
    },
}));

export default function Category(props: { category: ICategory }) {
    const classes = useStyles();

    const category = props.category;

    return (
        <Grid container item direction="row" alignItems="flex-start" spacing={0}>
            <Grid item xs={3}>
                <div className={classes.categoryIconArea}>
                    <img
                        className={classes.categoryIcon}
                        alt="complex"
                        src={`${process.env.PUBLIC_URL}/icons/${category.name}.svg`}
                    />
                </div>
            </Grid>
            <Grid container item xs={8} direction="column">
                <Grid item xs>
                    <div className={classes.categoryNameArea}>
                        <span className={classes.categoryName}>{category.name}</span>
                        {(() => {
                            // 星マークを作成
                            var star = "";
                            const blackStarNum = Math.trunc(category.score);
                            const whiteStarNum = 5 - blackStarNum;
                            for (let i = 0; i < blackStarNum; i++) {
                                star = star + "★";
                            }
                            for (let i = 0; i < whiteStarNum; i++) {
                                star = star + "☆";
                            }
                            return <span className={classes.starMark}>{star}</span>;
                        })()}
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.categoryArea}></div>
                </Grid>
                {category.details.map((detail, index) => (
                    <Grid item xs key={index}>
                        <div className={classes.categoryDetail}>
                            <span className={classes.categoryDetailTitle}>{detail.description}</span>
                            <span className={classes.categoryDetailContent}>{detail.value}</span>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
