import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    text: {
        textAlign: "center",
        fontWeight: "bolder",
        fontSize: "14px",
    },
    balloon: {
        position: "relative",
        display: "inline-block",
        background: "#FFA080",
        borderRadius: "30px",
        padding: "12px",
        paddingLeft: "40px",
        paddingRight: "40px",
        fontWeight: "bold",
        color: "#fff",
        height: "16px",
        "&::before": {
            content: '""',
            border: "10px solid transparent",
            borderTop: "10px solid #FFA080",
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
        },
    },
}));

export default function QuestionBalloon(props: { message: string }) {
    const classes = useStyles();

    const message = props.message;

    return (
        <div className={classes.balloon}>
            <span className={classes.text}>{message}</span>
        </div>
    );
}
