import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createTrackingWithUserIdTownId } from "../../useTracking";

const useStyles = makeStyles(() => ({
    shareButton: {
        background: "#f2f2f2",
        borderRadius: "10px",
        width: "320px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    shareIconArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    moveRankingIcon: {
        width: "20px",
        height: "20px",
        "@media screen and (max-width: 450px)": {
            width: "5vw",
            height: "5vw",
        },
    },
    shareText: {
        margin: "5px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
        color: "#212121",
    },
}));

export default function ShareURL() {
    const classes = useStyles();

    const copyUrl = async () => {
        await global.navigator.clipboard.writeText(window.location.href);
    };

    return (
        <div>
            <Button
                variant="outlined"
                className={classes.shareButton}
                onClick={() => {
                    copyUrl();
                    createTrackingWithUserIdTownId("pushedCopyUrlButton");
                }}
            >
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item xs={2} className={classes.shareIconArea}>
                        <img
                            className={classes.moveRankingIcon}
                            alt="complex"
                            src={`${process.env.PUBLIC_URL}/icons/icon_link_1.svg`}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <span className={classes.shareText}>URLをコピー</span>
                    </Grid>
                </Grid>
            </Button>
        </div>
    );
}
