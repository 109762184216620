import { useLocation } from "react-router-dom";

import { Toolbar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createSimpleTracking } from "../../useTracking";

const useStyles = makeStyles(() => ({
    topFoot: {
        height: "111px",
    },
    foot: {
        height: "26px",
    },
    copyright: {
        fontSize: "9px",
        color: "#696050",
        textAlign: "center",
    },
    link: {
        fontSize: "9px",
        color: "#696050",
        textAlign: "center",
        cursor: "pointer",
    },
}));

export default function Footer() {
    const classes = useStyles();
    const location = useLocation();

    const topFooter = (
        <Toolbar className={classes.topFoot}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <div className={classes.copyright}>
                        <span>©上京物語2022</span>
                    </div>
                </Grid>
                <Grid container item direction="row" spacing={0}>
                    <Grid item xs={4}>
                        <div className={classes.link}>
                            <span
                                onClick={() => {
                                    createSimpleTracking("pushedOfficialSite");
                                    window.location.href = `https://user.tokyo-story.net/`;
                                }}
                            >
                                公式サイト
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.link}>
                            <span
                                onClick={() => {
                                    createSimpleTracking("pushedTerms");
                                    window.location.href = `https://user.tokyo-story.net/terms`;
                                }}
                            >
                                利用規約
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.link}>
                            <span
                                onClick={() => {
                                    createSimpleTracking("pushedPrivacy");
                                    window.location.href = `https://user.tokyo-story.net/privacy`;
                                }}
                            >
                                プライバシーポリシー
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Toolbar>
    );

    const footer = <Toolbar className={classes.foot}></Toolbar>;

    return (
        <div>
            {(() => {
                if (location.pathname === "/") return topFooter;
                else return footer;
            })()}
        </div>
    );
}
