import { Grid, ButtonBase, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ITown, ITownClick } from "../../models/model";

import { createTrackingWithUserIdTownId } from "../../useTracking";

const useStyles = makeStyles(theme => ({
    card: {
        padding: "15px",
        borderTop: "2px solid #6DD1F2",
        borderLeft: "2px solid #6DD1F2",
        borderRight: "2px solid #6DD1F2",
        borderImage: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: "1",
        width: "290px",
    },
    buttonCard: {
        marginBottom: "8px",
        borderBottom: "2px solid #6DD1F2",
        borderLeft: "2px solid #6DD1F2",
        borderRight: "2px solid #6DD1F2",
        borderImage: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderImageSlice: "1",
        width: "320px",
    },
    rankIcon: {
        position: "absolute",
        width: "70px",
        height: "70px",
        bottom: "65px",
        "@media screen and (max-width: 450px)": {
            bottom: "60px",
        },
    },
    cityName: {
        fontSize: "20px",
        textAlign: "left",
        fontWeight: "bolder",
        lineHeight: "120%",
    },
    image: {
        borderRadius: "10px",
        objectFit: "cover",
        width: "120px",
        height: "120px",
        "@media screen and (max-width: 450px)": {
            width: "30vw",
            height: "30vw",
        },
    },
    imageArea: {
        margin: "auto",
        display: "block",
    },
    stationTag: {
        color: "#FFFFFF",
        backgroundColor: "#BAA488",
        padding: "4px",
        margin: "5px",
        width: "35px",
        height: "15px",
        fontSize: "14px",
        fontWeight: "bolder",
        "@media screen and (max-width: 450px)": {
            width: "10vw",
            height: "2vh",
        },
    },
    stationName: {
        fontSize: "16px",
        fontWeight: "bolder",
    },
    matchArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    matchText: {
        fontSize: "10px",
        margin: "5px",
    },
    matchNum: {
        fontSize: "30px",
        color: "#FF8459",
        fontWeight: "bolder",
    },
    moveTownTextArea: {
        textAlign: "left",
    },
    moveTownText: {
        fontSize: "16px",
        fontWeight: "bolder",
        margin: "10px",
    },
    moveTownArrowArea: {
        textAlign: "right",
    },
    moveTownArrow: {
        fontSize: "16px",
        fontWeight: "bolder",
    },
    moveTownButton: {
        color: "#00B3A7",
        background: "#e6fbf0",
        marginTop: theme.spacing(0),
        width: "320px",
        height: "80px",
    },
}));

export default function TownCardBig(props: { town: ITown; townClick: ITownClick }) {
    const classes = useStyles();

    const town = props.town;

    const townClick = (townId: number) => {
        props.townClick(townId);
    };

    return (
        <div>
            <Card className={classes.card} elevation={0}>
                <Grid container direction="row" spacing={2}>
                    <Grid item container xs={5} direction="column">
                        <Grid item xs>
                            <ButtonBase className={classes.imageArea}>
                                <img
                                    className={classes.rankIcon}
                                    alt="complex"
                                    src={`${process.env.PUBLIC_URL}/icons/no${town.rank}.svg`}
                                />
                                <img
                                    className={classes.image}
                                    alt="complex"
                                    src={`/api/v3/town_image/${town.town_id}`}
                                />
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <Grid item container xs={7} direction="column" spacing={1}>
                        <Grid item xs>
                            <div className={classes.cityName}>{`${town.ward_name} ${town.town_name}`}</div>
                        </Grid>
                        <Grid item xs>
                            <div>
                                <span className={classes.stationTag}>最寄駅</span>
                                <span className={classes.stationName}>{town.nearest_station}</span>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div className={classes.matchArea}>
                                <span className={classes.matchText}>マッチ度</span>
                                <span className={classes.matchNum}>{`${town.matching_score}%`}</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.buttonCard} elevation={0}>
                <ButtonBase
                    focusRipple
                    className={classes.moveTownButton}
                    onClick={() => {
                        townClick(town.town_id);
                        createTrackingWithUserIdTownId("pushedViewDetail");
                    }}
                >
                    <img alt="complex" src={`${process.env.PUBLIC_URL}/icons/街詳細画面.svg`} />
                </ButtonBase>
            </Card>
        </div>
    );
}
