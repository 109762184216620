import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ICity } from "../../models/model";

const useStyles = makeStyles(theme => ({
    miniPaper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "block",
        backgroundColor: "#FFFFF",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    cityPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        margin: "auto",
        border: "1px solid #F1DCA7",
        display: "block",
        width: "320px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    cityTitleArea: {
        paddingLeft: theme.spacing(2),
    },
    cityTitle: {
        fontWeight: "bolder",
        fontSize: "16px",
    },
    cityInfoTitle: {
        color: "#FFFFFF",
        backgroundColor: "#BAA488",
        display: "inline-block",
        textAlign: "left",
        fontSize: "15px",
        width: "312px",
        padding: "4px",
        "@media screen and (max-width: 450px)": {
            width: "83vw",
            padding: "1vw",
        },
    },
    itemArea: {
        padding: "20px",
        borderTop: "0.5px solid #F1DCA7",
        borderBottom: "0.5px solid #F1DCA7",
    },
    itemName: {
        fontWeight: "bolder",
        fontSize: "14px",
    },
    itemDetail: {
        fontSize: "12px",
    },
}));

export default function TownDetailCity(props: { city: ICity }) {
    const classes = useStyles();

    const cityName = props.city.name;
    const cityInfo = props.city.info;

    return (
        <Paper className={classes.miniPaper} square elevation={0}>
            <Grid container direction="column" spacing={0}>
                <Grid item xs>
                    <div className={classes.cityTitleArea}>
                        <span className={classes.cityTitle}>{cityName}の行政サービス</span>
                    </div>
                </Grid>
                <Grid container item direction="column" spacing={0}>
                    <Paper className={classes.cityPaper} square elevation={0}>
                        {cityInfo.map((city, index) => (
                            <Grid container item direction="column" spacing={0} key={index}>
                                <Grid item xs>
                                    <div>
                                        <span className={classes.cityInfoTitle}>{city.title}</span>
                                    </div>
                                </Grid>
                                <Grid container item direction="column" spacing={0}>
                                    {city.items.map((item, index) => (
                                        <Grid item xs key={index} className={classes.itemArea}>
                                            <div>
                                                <span className={classes.itemName}>{item.name}</span>
                                            </div>
                                            <div>
                                                <span className={classes.itemDetail}>{item.detail}</span>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}
