import { Card, Grid, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    imageArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    catImage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        objectFit: "contain",
        height: "100px",
    },
    typeImage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        objectFit: "contain",
        height: "200px",
    },
    miniPaper: {
        margin: "auto",
        display: "block",
        width: "320px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    recommendMessageArea: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    recommendMessage: {
        fontSize: "16px",
        fontWeight: "bolder",
    },
}));

export default function TownDetailRealEstateAgencyCaution() {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paper} square elevation={0}>
                <Grid container direction="column" alignItems="center" spacing={1}>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_1.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_2.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_3.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Card className={classes.miniPaper} square>
                            <div className={classes.recommendMessageArea}>
                                <p className={classes.recommendMessage}>関わってはいけない不動産屋の特徴</p>
                                <p>
                                    ①物件の申込や契約を急かしてくる
                                    <br />
                                    ②物件のメリットばかり話してくる
                                    <br />
                                    ③存在しない物件を掲載（おとり物件）
                                </p>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_4.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_5.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_6.svg`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Button
                            className={classes.imageArea}
                            onClick={() => {
                                window.open("https://user.tokyo-story.net/partner/selection");
                            }}
                        >
                            <img
                                className={classes.typeImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/town_detail_partner_selection.svg`}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.imageArea}>
                            <img
                                className={classes.catImage}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/images/cat_caution_7.svg`}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
