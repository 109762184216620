import { makeStyles } from "@material-ui/core/styles";

import { IReturnClick } from "../../models/model";

const useStyles = makeStyles(theme => ({
    recommendTag: {
        marginTop: theme.spacing(1),
        color: "#FFFFFF",
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        paddingLeft: "20px",
        fontSize: "12px",
        fontWeight: "bold",
        width: "375px",
        height: "23px",
        cursor: "pointer",
        "@media screen and (max-width: 450px)": {
            width: "95vw",
            height: "3vh",
        },
    },
}));

export default function TownDetailReturnButton(props: { returnClick: IReturnClick }) {
    const classes = useStyles();

    const returnClick = () => {
        props.returnClick();
    };

    return (
        <div>
            <span
                className={classes.recommendTag}
                onClick={() => {
                    returnClick();
                }}
            >
                {`＜ 戻る`}
            </span>
        </div>
    );
}
