import { Paper, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IReturnClick } from "../../models/model";

const useStyles = makeStyles(theme => ({
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "375px",
        "@media screen and (max-width: 450px)": {
            width: "100vw",
        },
    },
    explanationBig: {
        fontSize: "14px",
        textAlign: "center",
        fontWeight: "bolder",
    },
    moveRankingArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    moveRankingText: {
        margin: "5px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
        color: "#FFFFFF",
    },
    moveRankingIconArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    moveRankingIcon: {
        width: "20px",
        height: "20px",
        "@media screen and (max-width: 450px)": {
            width: "5vw",
            height: "5vw",
        },
    },
    moveRankingButton: {
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        borderRadius: "30px",
        width: "320px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
}));

export default function TownDetailReturnRankingButton(props: { returnRankingClick: IReturnClick }) {
    const classes = useStyles();

    const returnRankingClick = () => {
        props.returnRankingClick();
    };

    return (
        <Paper className={classes.paper} square elevation={0}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item xs>
                    <span className={classes.explanationBig}>おススメベスト１０位をさらに見る場合は↓</span>
                </Grid>
                <Grid item xs></Grid>
                <Button
                    variant="outlined"
                    className={classes.moveRankingButton}
                    onClick={() => {
                        returnRankingClick();
                    }}
                >
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                        <Grid item xs={2} className={classes.moveRankingIconArea}>
                            <img
                                className={classes.moveRankingIcon}
                                alt="complex"
                                src={`${process.env.PUBLIC_URL}/icons/icon_crown.svg`}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <span className={classes.moveRankingText}>診断結果ランキングへ戻る</span>
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        </Paper>
    );
}
