import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (window && window.gtag) {
            window.gtag("event", "page_view", {
                // アクセスしたパスを送付する
                page: location.pathname,
            });
        }
    }, [location]);
};

// userIdとtownIdを受け取るカスタムイベントを作成
const createTrackingWithUserIdTownId = action => {
    const params = new URLSearchParams(window.location.search);

    const userId = params.get("user_id");
    const townId = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);

    window.gtag("event", action, {
        category: "conversion",
        userId: userId,
        townId: townId,
    });
};

// 引数のないカスタムイベント作成
const createSimpleTracking = action => {
    window.gtag("event", action);
};

export { usePageTracking, createTrackingWithUserIdTownId, createSimpleTracking };
