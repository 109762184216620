import { useState, useEffect } from "react";

import { Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ITownDetail } from "../../models/model";

const useStyles = makeStyles(theme => ({
    miniPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        margin: "auto",
        display: "block",
        width: "320px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    recommendTag: {
        color: "#FFFFFF",
        background: "linear-gradient(to right, #6DD1F2, #7AEBB7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px",
        fontSize: "13px",
        width: "160px",
        height: "19px",
        "@media screen and (max-width: 450px)": {
            width: "50vw",
            height: "3vh",
        },
    },
    characterArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    character: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        objectFit: "contain",
        height: "200px",
    },
    linkArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    copyrightArea: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    copyright: {
        fontSize: "10px",
    },
    recommendMessageArea: {
        padding: theme.spacing(3),
    },
    recommendMessage: {
        fontSize: "16px",
    },
}));

export default function TownDetailSecond(props: { townDetail: ITownDetail }) {
    const classes = useStyles();

    // キャラクターを表示するか
    const [isCharacter, setCharacter] = useState(false);
    // 埋め込みリンクを表示するか
    const [isLink, setIsLink] = useState(false);

    const townDetail = props.townDetail;

    // キャラクターの画像があるか判定
    const checkCharacter = (url: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => reject(url);
        });
    };

    useEffect(() => {
        checkCharacter(`${process.env.PUBLIC_URL}/images/character/${townDetail.character}`)
            .then(() => {
                setCharacter(true);
            })
            .catch(() => {});

        if (townDetail.town_link.link_type && townDetail.town_link.url) {
            setIsLink(true);
        }
    });

    // キャラクター
    const characterView = (
        <div>
            <Grid item xs>
                <div className={classes.characterArea}>
                    <img
                        className={classes.character}
                        alt="complex"
                        src={`${process.env.PUBLIC_URL}/images/character/${townDetail.character}`}
                    />
                </div>
            </Grid>
            <Grid item xs>
                <div className={classes.copyrightArea}>
                    <span className={classes.copyright}>{townDetail.copyright}</span>
                </div>
            </Grid>
        </div>
    );

    // 埋め込みリンク
    const LinkView = (
        <div>
            <Grid item xs>
                <div className={classes.linkArea}>
                    <iframe
                        height="200"
                        src={townDetail.town_link.url}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </Grid>
        </div>
    );

    return (
        <Card className={classes.miniPaper} square>
            <Grid container item direction="column" justifyContent="center" spacing={1}>
                <Grid item xs>
                    <div>
                        <span className={classes.recommendTag}>あなたへのオススメ point</span>
                    </div>
                </Grid>
                {(() => {
                    if (isCharacter) return characterView;
                    else return <div></div>;
                })()}
                {(() => {
                    if (isLink) return LinkView;
                    else return <div></div>;
                })()}
                <Grid item xs>
                    <div className={classes.recommendMessageArea}>
                        <span className={classes.recommendMessage}>{townDetail.message}</span>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
}
