import { Player } from "@lottiefiles/react-lottie-player";

export default function Loading() {
    return (
        <div>
            <Player
                autoplay={true}
                loop={true}
                controls={true}
                src={`${process.env.PUBLIC_URL}/lottie/loading.json`}
                style={{ height: "300px", width: "300px" }}
            ></Player>
        </div>
    );
}
