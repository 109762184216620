import { LineShareButton, LineIcon } from "react-share";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createTrackingWithUserIdTownId } from "../../useTracking";

const useStyles = makeStyles(() => ({
    shareButton: {
        borderRadius: "10px",
        width: "320px",
        height: "50px",
        "@media screen and (max-width: 450px)": {
            width: "85vw",
        },
    },
    shareIconArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    shareText: {
        margin: "5px",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bolder",
        color: "#FFFFFF",
    },
}));

export default function ShareLine() {
    const classes = useStyles();

    return (
        <div>
            <LineShareButton
                className={classes.shareButton}
                style={{ background: "#00b800" }}
                url={window.location.href}
                title="上京物語"
                onClick={() => {
                    createTrackingWithUserIdTownId("pushedShareLineButton");
                }}
            >
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item xs={2} className={classes.shareIconArea}>
                        <LineIcon size={32} round />
                    </Grid>
                    <Grid item xs={8}>
                        <span className={classes.shareText}>LINEでシェアする</span>
                    </Grid>
                </Grid>
            </LineShareButton>
        </div>
    );
}
