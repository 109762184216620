import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";

import { Grid } from "@material-ui/core";

import Loading from "../ui/Loading";
import TownDetailHead from "../ui/TownDetailHead";
import TownDetailRecommend from "../ui/TownDetailRecommend";
import TownDetailMap from "../ui/TownDetailMap";
import TownDetailMatchingScore from "../ui/TownDetailMatchingScore";
import TownDetailCity from "../ui/TownDetailCity";
// import TownDetailNeighborhood from "../ui/TownDetailNeighborhood";   // 近隣の街 useStateなどを使っていて、必要な時に復活させるのが面倒なのでコメントアウトで対応
import TownDetailTownSearch from "../ui/TownDetailTownSearch";
import TownDetailRealEstateAgencyCaution from "../ui/TownDetailRealEstateAgencyCaution";
import TownDetailRealEstateAgencySelection from "../ui/TownDetailRealEstateAgencySelection";
import TownDetailReturnButton from "../ui/TownDetailReturnButton";
import TownDetailReturnRankingButton from "../ui/TownDetailReturnRankingButton";

import { ITownDetail, IReturnClick, ICategory, ICity } from "../../models/model";

export default function TownDetail() {
    const navigate = useNavigate();
    const location = useLocation();

    // スクロールトップに表示
    window.scrollTo(0, 0);

    // URLパラメータ
    const urlParam = useParams();
    const [searchParams] = useSearchParams();

    // ロードを表示するか？
    const [isLoading, setLoading] = useState(true);
    // ユーザーID
    const [userId] = useState<string>(searchParams.get("user_id") as string);
    // タウンID
    const [townId, setTownId] = useState(urlParam.town_id);
    // 街情報
    const [townDetail, setTownDetail] = useState<ITownDetail>({} as ITownDetail);
    // 街のカテゴリ
    const [categories, setCategories] = useState<ICategory[]>([]);
    // const [neighborhoodList, setNeighborhoodList] = useState<ITown[]>([]);
    // 区情報
    const [city, setCity] = useState<ICity>({} as ICity);

    // 街取得API
    const getTown = () => {
        axios.get(`/api/v3/town/${townId}?user_id=${userId}`).then(res => {
            setTownDetail(res.data);
            setCategories(res.data.categories);
            // setNeighborhoodList(res.data.neighborhood);
            getCityInfo(res.data.ward_name);
        });
    };

    // 区情報取得API
    const getCityInfo = (townName: string) => {
        axios.get(`/api/v3/city_info?city_name=${townName}`).then(res => {
            setCity(res.data);
            setLoading(false);
        });
    };

    // 街取得
    useEffect(() => {
        getTown();
    }, [townId]);

    // URLが変更されたら画面を再描画
    useEffect(() => {
        setLoading(true);
        setTownId(urlParam.town_id);
    }, [location]);

    // GoogleMap埋め込みhtml
    const iframeSource: string = `<iframe width="320" height="200" src="https://maps.google.co.jp/maps?ll=${townDetail.latitude},${townDetail.longitude}&q=${townDetail.latitude},${townDetail.longitude}&amp;output=embed&amp;t=m&amp;z=17" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>`;

    const returnClick: IReturnClick = () => {
        // ひとつ前に戻る
        navigate(-1);
    };

    const returnRankingClick: IReturnClick = () => {
        // ランキングに戻る
        navigate(`/ranking?user_id=${userId}`, { state: { type: "success" } });
    };

    // 街画面
    const townView = (
        <div>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <TownDetailReturnButton returnClick={returnClick} />
                <TownDetailHead townDetail={townDetail} />
                <TownDetailRecommend townDetail={townDetail} />
                <TownDetailMap iframeSource={iframeSource} />
                <TownDetailMatchingScore townDetail={townDetail} categories={categories} />
                <TownDetailCity city={city} />
                {/* <TownDetailNeighborhood neighborhoodList={neighborhoodList} userId={userId} /> */}
                <TownDetailTownSearch userId={userId} />
                <TownDetailRealEstateAgencyCaution />
                <TownDetailRealEstateAgencySelection townDetail={townDetail} />
                <TownDetailReturnRankingButton returnRankingClick={returnRankingClick} />
            </Grid>
        </div>
    );

    return (
        <div>
            {(() => {
                if (isLoading) return <Loading />;
                else return townView;
            })()}
        </div>
    );
}
